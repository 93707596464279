:root {
    --color-primary: #1FA22E;
    --color-primary-75: #1FA22E75;
    --color-secondary: #3D4B56;
    --color-text-default: #1F1F1F;
    --color-text-muted: #868E96;

    --color-default: #1F1F1F;
    --color-white: #FFF;
    --color-light-grey: #F8F8F8;
    --color-grey: #C8C8C8;
    --color-border-grey: #dee2e6;
    --color-dark-grey: #8D8D8D;
    --color-dark: #343A40;
    --color-highlight: #646363;

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #177822;
    --color-secondary-dark: #283239;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #3A88C4;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "HelveticaNeue-light", sans-serif;
    --font-default-bold: "HelveticaNeue-bold", sans-serif;

    --font-size-default: calc(14rem/16);
    --line-height-default: calc(23/14);


    --color-purple: #572381;
    --color-light-purple: #C2AFCD;
    --color-middle-purple: #906899;

    --color-green: var(--color-primary);
    --color-light-green: #80C356;
    --color-middle-green: #C6D76B;

    --font-default-weight: 100;

}
